
// export async function isShouQuans (_this){
//     const {data} =  await _this.$http.post('/index.php/index/wechat.bid_wechat/redirect_uri',{url:window.location.href,shopId:_this.$route.query.shopId,id:_this.$route.query.id}).then(res=>res) 
//     console.log(data)
//         if(data.code == 0) {
//             window.localStorage.setItem('isShouQuan',1)
           
//         }else if(data.code == 2){
//             // window.location.href = data.data
//         }
    
// }


export async function NO1ShouQuans(_this){
    
    let url = window.location.origin
    let shopId = _this.$route.query.shopId
    let type = _this.$route.query.type
    let path = _this.$route.path
    let id = _this.$route.query.id
   
    let urls = window.location.href + '&path=' + path +'&id=' + id
    // let urls ="https://wechat.chexd.com/chaichejian/BiddingList.html?domain=" +domain + '&url=' +url + '&shopId=' + shopId + '&type=' +type + '&path=' + path +'&id=' + id
    let op = window.localStorage.getItem('openid') || ''

    _this.$http.post('/index.php/BidOrder/getShopName',{shopId}).then(res=>{
        if(res.data.code == 0){
            window.localStorage.setItem('getShopName',res.data.data)
        }
    })
   
        
   
    if(!op){
        const {data} =  await _this.$http.post('/index.php/index/wechat.bid_wechat/redirect_uri',{url:window.location.href,shopId}).then(res=>res)
            if(data.code == 0) {
                let openid = data.data.openid 
                window.localStorage.setItem('openid',openid)
            }else if(data.code == 2){
                window.location.href = data.data
            }else{
                // window.localStorage.setItem('isShouQuan',res.data.data.wechatFansInfo.openid)
                _this.$toast.fail(data.msg)
            }
        }
    
}
