import request from './request'

//工序工位
export function PlateNum(data) {
  return request({
    url: '/index.php/filter/carNumberPlate',
    method: 'post',
    data
  })
}
export function selVehicLesInfo(data) {
  return request({
    url: '/index.php/filter/selVehicLesInfo',
    method: 'post',
    data
  })
}
export function findVehicles(data) {
  return request({
    url: '/index.php/filter/findVehicles',
    method: 'post',
    data
  })
}
export function updateVehicles(data) {
  return request({
    url: '/index.php/filter/updateVehicles',
    method: 'post',
    data
  })
}


